import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import Select from 'react-select'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faCommentSms, faMobileScreen, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle, faListCheck } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import CampaignOptionsModal from "../../components/modal/CampaignOptionsModal"
import DateTimePickerModal from "../../components/campaign/DateTimePickerModal"
import EffModal from '../../components/shared/EffModal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ModalApiStatus from "../../components/modal/ModalApiStatus"

export default function SmsCampaign() {
    // state
    const { t, i18n } = useTranslation('SmsCampaign', 'common')
    const initSegments = []
    const initTemplates = []
    const initDirectory = {
        dire_name: '',
        tags: [{name: '-', count: '-'}],
    }

    const initModalData = {
        "segments": initSegments,
        "templates": initTemplates,
        "id": "",
        // Efficiency
        "campaign_name": "",
        'total_sms': "Loading",
        'cost': "Loading",
        'send_time': "Loading",
        'sms_success_count': "Loading",
        'sms_failed_count': "Loading",
        'sms_failed_contacts_issue_count': "Loading",
        'sms_failed_phone_num_issue_count': "Loading",
        'sms_failed_telecom_blacklist_count': "Loading",
        'sms_failed_others_reason_count': "Loading",
        'sms_success_rate': 0,
        'sms_failed_contacts_issue': 0,
        'sms_failed_phone_num_issue': 0,
        'sms_failed_telecom_blacklist': 0,
        'sms_failed_others_reason': 0,
        template_name: '',
        directory: initDirectory,
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const datetime_now = new Date()
    const initScheduleTime = new Date(datetime_now.setHours(datetime_now.getHours() + parseInt(15 * Math.ceil(datetime_now.getMinutes() /15)/60),(15 * Math.ceil(datetime_now.getMinutes() /15)) % 60, 0))
    const sms_templates = []
    const initTemplateOptions = []
    const initlModal = {
        "data": initModalData,
        "templateOptions": initTemplateOptions,
        "scheduleTime": initScheduleTime,
        "action": initModalAction,
        "sms_templates": sms_templates,
        "loaded": true,
        "show": false,
        "warningModalShow": false,
        "optionsModalShow": false,
        "scheduleModalShow": false,
        "sendTestModalShow": false,
        "efficiencyModal": false
    }
    const initSendingData = {
        "campaign_name": "",
        "dire_id": "",
        "dire_name": "",
        "sms_id": "",
        "sms_name": "",
        "sendtime": "",
        "status": ""
    }
    const initPieData = []
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
        navigateToPayment: false,
        balance: 0,
        cost: 0,
    }
    const [pieData, setPieData] = useState(initPieData)
    const [testPhone, setTestPhone] = useState('')
    const [testName, setTestName] = useState('')
    const [testMembers, setTestMembers] = useState([])
    const [modal, setModal] = useState(initlModal)
    const [tableList, setTableList] = useState(false)
    const hrLine = <hr/>
    const [sendingData, setSendingData] = useState(initSendingData)
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    // validation
    const initInputCheck = {
        "campaign_name": {
            "required": true,
            "valid": true,
        },
        "dire_id": {
            "required": true,
            "valid": true,
        },
        "sms_id": {
            "required": true,
            "valid": true,
        }
    }
    const initErrors = {
        "required": {},
        "valid": {}
    }
    const [errors, setErrors] = useState(initErrors)

    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "scheduleTime": new Date(initScheduleTime),
            "data": initModalData,
            "action": initModalAction,
            "show": false,
            "warningModalShow": false,
            "optionsModalShow": false,
            "scheduleModalShow": false,
            "sendTestModalShow": false,
            "efficiencyModal": false,
            "loaded": true,
        }))
        setSendingData(initSendingData)
        setErrors(initErrors)
        setTestName('')
        setTestPhone('')
        setApiStatus(initApiStatus)

    }

    const handleModalShow = (event) => {
        let selected_campaign = undefined
        switch (event.target.name) {
            case "create":
                setErrors(initErrors)
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "edit":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                getCampaignSpecific(event.target.value)

                setModal(prevState => ({
                    ...prevState,
                    // "data": {
                    //     "id": selected_campaign.campaign_id,
                    //     "campaign_name": selected_campaign.campaign_name,
                    //     "sms_template": selected_campaign.sms_id,
                    // },
                    "action": {
                        "type": "edit",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            case "delete":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    // "data": {
                    //     "id": selected_campaign.campaign_id,
                    // },
                    "action": {
                        "type": "delete",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            //// Sending options series
            // 1. Menu
            case "options":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "options",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": true,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            
            // 2. Schedule(draft-to-schedule)
            case "draft-to-scheduled":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "action": {
                        "type": "draft-to-scheduled",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": true,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            // 3. Send Directly(draft-to-delivered)
            case "draft-to-delivered":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "action": {
                        "type": "draft-to-delivered",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            // Pause a scheduled campaign(scheduled-to-draft)
            case "scheduled-to-draft":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "action": {
                        "type": "scheduled-to-draft",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            
            // Send testing sms to a specific phone number
            case "send-test":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                getTestMembers()
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "send-test",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": true,
                    "efficiencyModal": false
                }))
                break
            case "get-efficiency":
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                getCampaignEfficiency(selected_campaign.campaign_id)
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "get-efficiency",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": true
                }))
                break

            case 'replicate':
                selected_campaign = tableList.find(item => item.campaign_id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "replicate",
                        "targetId": selected_campaign.campaign_id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break            
            default:
                break
        }
    }

    if (errors.required.campaign_name !== undefined && sendingData.campaign_name !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "campaign_name": undefined,
            }
        }))
    } else if (errors.required.sms_id !== undefined && sendingData.sms_id !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "sms_id": undefined,
            }
        }))
    } else if (errors.required.dire_id !== undefined && sendingData.dire_id !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "dire_id": undefined,
            }
        }))
    } 


    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                let requiredErrors = {}
                let fieldKeyArr = [ "sms_id", "dire_id" ]
                setErrors(initErrors)
                if (initInputCheck.campaign_name.required === true && sendingData.campaign_name === "") {
                    requiredErrors.campaign_name = "必填"
                }
                for (let i = 0; i < fieldKeyArr.length; i++) {
                    if (initInputCheck[fieldKeyArr[i]].required === true) {
                        if (sendingData[fieldKeyArr[i]] === "") {
                            requiredErrors[fieldKeyArr[i]] = "必選"
                        }
                    }
                }
                setErrors(prevState => ({
                    ...prevState,
                    "required": requiredErrors,
                }))
                if (Object.keys(requiredErrors).length === 0) {
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "warningModalShow": true,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                }
                break
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
                
            case "draft-to-scheduled":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "send-test":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
            break
            case "trans-click-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-click-tag",
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unclick-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-unclick-tag",
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            default:
                //pass
                break
        }
    }

    const handleWarningModalCancel = (event) => {
        switch (event.target.name){
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "draft-to-scheduled":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": true,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "draft-to-delivered":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": true,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "scheduled-to-draft":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "send-test":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-click-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unclick-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "replicate":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
        }
    }

    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                postCampaign()
                break
            case "edit":
                putCampaign()
                break
            case "delete":
                deleteCampaign()
                break
            case "draft-to-scheduled":
                sendCampaign()
                break
            case "draft-to-delivered":
                sendCampaign()
                break
            case "scheduled-to-draft":
                unscheduleCampaign()
                break
            case "send-test":
                sendCampaign()
                break
            case "trans-click-tag":
                transClickTag()
                break
            case "trans-unclick-tag":
                transClickTag()
                break
            case 'replicate':
                replicateCampaign()
                break
            default:
                //pass
                break
        }
    }
    // transClickTag
    async function transClickTag() {
        setModal(prevState => ({
            ...prevState,
            'loaded': false
        }))
        switch (modal.action.type) {
            case 'trans-click-tag':
                await apiAuthInstance({
                    // url: 'contact/remarketing-tag/',
                    url: 'remarketing/sms/short_url/',
                    method: 'post',
                    params: {
                        // source: 'sms',
                        campaign_id: modal.action.targetId,
                        positive: 'true',
                        action: 'click',
                    }
                }).then((response) => {
                        setModal(prevState => ({
                            ...prevState,
                            'loaded': true,
                            'show': false,
                            'warningModalShow': false,
                            'optionsModalShow': false,
                            'scheduleModalShow': false,
                            'sendTestModalShow': false,
                            'efficiencyModal': false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                .catch((error) => {
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break
            case 'trans-unclick-tag':
                await apiAuthInstance({
                    url: 'remarketing/sms/short_url/',                    
                    method: 'post',
                    params: {
                        source: 'sms',
                        campaign_id: modal.action.targetId,
                        positive: 'false',
                        action: 'click',
                    }
                }).then((response) => {
                        setModal(prevState => ({
                            ...prevState,
                            'loaded': true,
                            'show': false,
                            'warningModalShow': false,
                            'optionsModalShow': false,
                            'scheduleModalShow': false,
                            'sendTestModalShow': false,
                            'efficiencyModal': false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                .catch((error) => {
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                    break
            default:
                break
        }
    }
    // Create a new campaign
    async function postCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "sms/campaigns/",
            "method": "post",
            "data": {
                "campaign_name": sendingData.campaign_name,
                "dire_id": sendingData.dire_id,
                "sms_id": sendingData.sms_id,
                "sendtime": sendingData.sendtime
                // "campaign_name": modal.data.campaign_name,
                // "dire_id": modal.data.dire_id,
                // "sms_id": modal.data.sms_id,
                // "sendtime": modal.data.sendtime
            }
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            setSendingData(initSendingData)
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Update a draft campaign
    async function putCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "sms/campaigns/" + modal.action.targetId + "/",
            "method": "put",
            "data": {
                "campaign_name": sendingData.campaign_name,
                "dire_id": sendingData.dire_id,
                "sms_id": sendingData.sms_id,
                "sendtime": sendingData.sendtime
            }
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            setSendingData(initSendingData)
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Send a campaign
    async function sendCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        switch (modal.action.type) {
            // Schedule
            case "draft-to-scheduled":
                await apiAuthInstance({
                    "url": 'sms/campaigns/' + modal.action.targetId + "/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "sendtime": modal.scheduleTime.getFullYear().toString() + (modal.scheduleTime.getMonth() + 1).toString().padStart(2, "0") + modal.scheduleTime.getDate().toString().padStart(2, "0") + modal.scheduleTime.getHours().toString().padStart(2, "0") + modal.scheduleTime.getMinutes().toString().padStart(2, "0") + "00"
                    }
                }).then((response) => {
                    getCampaigns()
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    if (error.response.data.message === '2014') {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                            navigateToPayment: true,
                            balance: error.response.data.balance,
                            cost: error.response.data.cost,
                        })} else {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                    })}
                })
                break
            case "draft-to-delivered":
                await apiAuthInstance({
                    "url": 'sms/campaigns/' + modal.action.targetId + "/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "sendtime": "",
                    }
                }).then((response) => {
                    getCampaigns()
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    if (error.response.data.message === '2014') {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                            navigateToPayment: true,
                            balance: error.response.data.balance,
                            cost: error.response.data.cost,
                        })} else {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                    })}
                })
                break
            case "send-test":
                await apiAuthInstance({
                    "url": 'sms/campaigns/' + modal.action.targetId + "/test/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "phone": testPhone,
                    },
                }).then((response) => {
                    setTestPhone('')
                    setTestName('')
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    if (error.response.data.message === '2014') {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                            navigateToPayment: true,
                            balance: error.response.data.balance,
                            cost: error.response.data.cost,
                        })} else {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                    })}
                })
                break
            default:
                break
        }
    }

    // Replicate a campaign
    async function replicateCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            url: `sms/campaigns/${modal.action.targetId}/replicate/`,
            method: "post",
        })
        .then((response) => {
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCampaigns()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    // Unschedule a campaign
    async function unscheduleCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": 'sms/campaigns/' + modal.action.targetId + "/unschedule/",
            "method": "delete",
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Delete a draft campaign
    async function deleteCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": 'sms/campaigns/' + modal.action.targetId + "/",
            "method": "delete",
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Get all campaigns(shown on the tablelist), templates, directories(segments), specific campaign data
    async function getCampaigns() {
        await apiAuthInstance({
            "url": "sms/campaigns/",
            "method": "get",
            "params": {
                "status": "all"
            }
        }).then((response) => {
            setTableList(response.data.campaigns)
        })
    }
    async function getTemplates() {
        await apiAuthInstance({
            "url": "sms/templates/",
            "method": "get",
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "templates": response.data
                }
            }))
        })
    }
    async function getDirectories() {
        await apiAuthInstance({
            "url": "contact/directory/",
            "method": "get",
            "params": {
                "channel": "true",
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "segments": response.data.data
                }
            }))
        })
    }
    // Get test members for sent-test
    async function getTestMembers() {
        await apiAuthInstance({
            "url": "contact/test-member/",
            "method": "get",
            "params": {
                "channel": "sms",
            }
        }).then((response) => {
            setTestMembers(response.data.data)
        })
    }
    
    // Get campaign data for editing
    async function getCampaignSpecific(campaignId) {
        await apiAuthInstance({
            "url": "sms/campaigns/" + campaignId + "/",
            "method": "get",
            "params": {
                "status": "update",
            }
        }).then((response) => {
            setSendingData({
                "campaign_name": response.data.campaign_name,
                "dire_id": response.data.dire_id,
                "sms_id": response.data.sms_id,
                "dire_name": response.data.dire_name,
                "sms_name": response.data.sms_name,
                "status": "update",
            })
        })
    }

    // Get campaign effciency
    async function getCampaignEfficiency(campaignId) {
        const report = await apiAuthInstance({
            "url": "sms/campaigns/" + campaignId + "/efficiency/",
            "method": "get",
        })
        const campaign = await apiAuthInstance({
            "url": "sms/campaigns/" + campaignId + "/",
            "method": "get",
        })
        setModal(prevState => ({
            ...prevState,
            "data": {
                ...prevState.data,
                'campaign_name': report.data.campaign_name,
                'campaign_message': report.data.campaign_message,
                'total_sms': report.data.total_sms,
                'cost': report.data.cost,
                'send_time': report.data.send_time,
                'sms_success_count': report.data.sms_success_count,
                'sms_failed_count': report.data.sms_failed_count,
                'sms_failed_contacts_issue_count': report.data.sms_failed_contacts_issue_count,
                'sms_failed_phone_num_issue_count': report.data.sms_failed_phone_num_issue_count,
                'sms_failed_telecom_blacklist_count': report.data.sms_failed_telecom_blacklist_count,
                'sms_failed_others_reason_count': report.data.sms_failed_others_reason_count,
                template_name: campaign.data.template_name,
                directory: campaign.data.directory,
            },
            "efficiencyModal": true
        }))
        setPieData([
                {item: 'sms_success_rate', count: report.data.sms_success_rate},
                {item: 'sms_failed_contacts_issue', count: report.data.sms_failed_contacts_issue},
                {item: 'sms_failed_phone_num_issue', count: report.data.sms_failed_phone_num_issue},
                {item: 'sms_failed_telecom_blacklist', count: report.data.sms_failed_telecom_blacklist},
                {item: 'sms_failed_others_reason', count: report.data.sms_failed_others_reason},
        ])
    }

    // Check schedules and sendings
    async function checkSchedule() {
        await apiAuthInstance({
            "url": 'sms/campaigns/check/status/',
            "method": "get",
            "params": {
                "status": "schedule",
            }
        })
    }
    async function checkSending() {
        await apiAuthInstance({
            "url": 'sms/campaigns/check/status/',
            "method": "get",
            "params": {
                "status": "sending",
            }
        })
    }

    useEffect(() => {
        getCampaigns()
    }, [])

    useEffect(() => {
        getTemplates()
        getDirectories()
    }, [modal.show, modal.sendTestModalShow])
    
    useEffect(() => {
        checkSchedule()
        checkSending()
    }, [])

    const onModalChange = (event, action) => {
        if (action !== undefined) {
            if (action.name === "message-type") {
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "message_type": event.id,
                        "line_template": ""
                    }
                }))
            } else if (action.name === "line-template") {
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "line_template": event.id
                    }
                }))
            } else if (action.type === "click") {
                let newScheduleDate = new Date(modal.scheduleTime).setFullYear(event.getFullYear(), event.getMonth(), event.getDate())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleDate)
                }))
            } else if (action.name === "time-hour") {
                let newScheduleHour = new Date(modal.scheduleTime).setHours(event.value)
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleHour)
                }))
            } else if (action.name === "time-minute") {
                let newScheduleMinute = new Date(modal.scheduleTime).setMinutes(event.value)
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleMinute)
                }))
            }
        } else {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }))
        }
    }

    // Campaign Statue Filter
    const stateSelectList = ['all', 'draft', 'on_schedule', 'sending', 'delivered']
    const [stateSelect, setStateSelect] = useState('')
    // State Filter
    let tableListData = []
    if (stateSelect === "draft") {
        tableListData = tableList.filter(campaign => campaign.status === "Save")
    } else if (stateSelect === "on_schedule") {
        tableListData = tableList.filter(campaign => campaign.status === "Schedule")
    } else if (stateSelect === "sending") {
        tableListData = tableList.filter(campaign => campaign.status === "Sending")
    } else if (stateSelect === "delivered") {
        tableListData = tableList.filter(campaign => campaign.status === "Sent")
    } else {
        tableListData = tableList
    }

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'create_time', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'create_time', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'create_time', type: 'date', aesc: true }},

        { name: 'update_time_new_to_old', value: { key: 'edit_time', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'edit_time', type: 'date', aesc: true }},
        { name: 'schedule_time_new_to_old', value: { key: 'schedule_time', type: 'date', aesc: false }},
        { name: 'schedule_time_old_to_new', value: { key: 'schedule_time', type: 'date', aesc: true }},
        { name: 'send_time_new_to_old', value: { key: 'send_time', type: 'date', aesc: false }},
        { name: 'send_time_old_to_new', value: { key: 'send_time', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')

    return (
        <div className="d-flex flex-column h-100 Table-AutoWidth">
            {tableList &&
                <Row xs="auto" className="mb-2 mailSendingTabs">
                    <div className="d-flex w-100">
                        <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                            {t('create_campaign', { ns: 'common'})}
                        </Button>
                        <Select
                            styles={purpleSelector}
                            className="ps-3 w-25 ms-auto me-3"
                            closeMenuOnSelect={true}
                            name="sendings-smsState"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            onChange={(event) => setStateSelect(event.value)}
                            options={stateSelectList.map(s => ({ value: s, label: t(`${s}`, { ns: 'common'}) }))}
                        />
                        <Form.Control className="w-25" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                        <Dropdown className="ps-3" size="sm" align="end">
                            <Dropdown.Toggle
                                className="btn-orcaLight"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                                &ensp;{t(`${sort.name}`, { ns: 'common'})}&ensp;
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {sortOptions.map((option, i) => 
                                    <Dropdown.Item
                                        key={`${i}_${option.name}`}
                                        href=""
                                        onClick={() => setSort(option)}
                                    >
                                        {t(`${option.name}`, { ns: 'common'})}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>  
                </Row>
            }

            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "campaign_name"
                    },
                    {
                        "head": "schedule_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "schedule_time"
                    },
                    {
                        "head": "send_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "send_time"
                    },
                    {
                        "head": "status",
                        "dataType": "status",
                        "fieldName": "status"
                    },  
                    {
                        "head": "functions",
                        "dataType": "functions in different status",
                        "fieldAsValueCampaignId": "campaign_id",
                        "fieldAsValueBatchId": "batch_id",
                        "status": "status",
                        "channel": "sms",
                        "onClick": handleModalShow,
                    }
                ],
                "data": tableListData && tableListData
                    .filter((t) => t.campaign_name.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !tableList,
                }}
            />

            <CampaignOptionsModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "optionsModalShow": modal.optionsModalShow,
                "onScheduleClick": handleWarningModalCancel,
                "onSendClick": handleModalShow,
                "onOptionsClick": handleModalShow,
                "onCloseClick": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <DateTimePickerModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "showPickyDateTime": true,
                "scheduleModalShow": modal.scheduleModalShow,
                "time": modal.scheduleTime,
                "onTimeChange": onModalChange,
                "onCloseClick": handleModalClose,
                "onSubmitClick": handleModalSubmit
            }}
            />

            {/* Create/Edit Campaign Modal */}
            <Modal show={modal.show} size="lg" >
                <Modal.Header>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_campaign', { ns: 'common'})}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_campaign', { ns: 'common'})}</Modal.Title> }
                    {/* <Modal.Title>Create SMS Campaign</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-3 py-2">
                        <Row className="h-100 w-100 m-0">
                            <Col xs={4} className="d-flex text-orcaLighter">
                                <FontAwesomeIcon className="my-auto ms-auto" style={{ height: '6.5rem' }} icon={faMobileScreen} />
                                <span className="my-auto me-auto pt-3">
                                    <FontAwesomeIcon className="pt-5 ps-1 fw-bolder" style={{ height: '3rem' }} icon={faCommentSms} />
                                </span>
                            </Col>
                            <Col xs={8}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="campaign-name">
                                            <Form.Label className="h6 mb-2 text-orca">{t('campaign_name', { ns: 'common'})}</Form.Label>
                                            <Form.Control
                                                maxLength="40"
                                                type="text"
                                                placeholder={t('campaign_name', { ns: 'common'})}
                                                defaultValue={sendingData.campaign_name}
                                                onChange={(event) => setSendingData({ 
                                                    ...sendingData, 
                                                    'campaign_name': event.target.value 
                                                })}
                                                style={errors.required.campaign_name !== undefined ? { border: '2px solid crimson' } : {}}
                                            />
                                            <Form.Text className="text-red">{errors.required.campaign_name}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pe-0">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="h6 mb-2 text-orca">{t('template_name', { ns: 'common'})}</Form.Label>
                                            <Select
                                                styles={purpleSelector}
                                                closeMenuOnSelect={true}
                                                name="sendings-smsTemplate"
                                                components={{ Option: TitledOptions, Control: TitledControl }}
                                                onChange={(event) => setSendingData({ 
                                                    ...sendingData, 
                                                    'sms_id': event.value, 
                                                    'sms_name': event.label 
                                                })}

                                                options={modal.data.templates.map(s => ({ value: s.sms_id, label: s.subject }))}
                                                value={{ label: sendingData.sms_name, value: sendingData.sms_id }}
                                            />
                                            <Form.Text className="text-red">{errors.required.sms_id}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="h6 mb-2 text-orca">{t('target_directory', { ns: 'common'})}</Form.Label>
                                            <Select
                                                styles={purpleSelector}
                                                closeMenuOnSelect={true}
                                                name="sendings-smsSegment"
                                                components={{ Option: TitledOptions, Control: TitledControl }}
                                                onChange={(event) => setSendingData({ 
                                                    ...sendingData, 
                                                    'dire_id': event.value, 
                                                    'dire_name': event.label 
                                                })}
                                                options={modal.data.segments.map(s => ({ value: s.dire_id, label: s.dire_name }))}
                                                value={{ label: sendingData.dire_name, value: sendingData.dire_id  }}
                                            />
                                            <Form.Text className="text-red">{errors.required.dire_id}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" name="modal-cancel" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* Modal Send Test */}
            <Modal show={modal.sendTestModalShow}>
                <Modal.Header closeButton onHide={handleModalClose} className="h4">
                    {t('send_test_message', { ns: 'common'})}
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Form.Group className="mb-3" controlId="formBasicSegment">
                            <Form.Label>{t('select_test_member', { ns: 'common'})}</Form.Label>
                            <Select
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="sendings-smsSegment"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                onChange={(event) => {setTestPhone(event.value); setTestName(event.label)}}
                                options={testMembers.map(testMember => ({ value: testMember.phone, label:  testMember.name + " / " + testMember.phone }))}
                                value={{ label: testName, value: testPhone }}
                            />
                        </Form.Group>
                    </Form>  
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>


            {/* Specific Campaign Efficiency Modal */}
            <Modal show={modal.efficiencyModal} size="xl">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('campaign_report', { ns: 'common'})}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4 pb-5 px-5">
                    <Row className="d-flex pt-3">
                        <Col xs={4}>
                            <Row className="d-flex">
                                <Col xs={10}
                                    className="fs-5 oneLineLimit pb-2"
                                    title={modal.data.template_name}
                                >
                                    {modal.data.template_name}
                                </Col>
                                <Col xs={2}
                                    className="text-end text-orcaLighter fs-6 align-self-end pb-2"
                                >
                                    {t('preview', { ns: 'common'})}
                                </Col>
                            </Row>
                            <div className="card text-start p-3 h-90 bg-orcaWhite">
                                <span className="scroller" style={{ overflowY:'auto'}}>
                                    {modal.data.campaign_message}
                                </span>
                            </div>
                        </Col>

                        <Col xs={8} className="ps-4 mb-auto">
                            <Row>
                                <EffModal 
                                    settings={{
                                        ...modal,
                                        pieData,
                                        labels: {
                                            title: 'label_title_campaign_overview',
                                            keys: {
                                                send_time: { label: 'key_label_send_time', type: 'time' },
                                                directory: { label: 'key_label_directory', type: 'directoryObject'},
                                                total_sms: { label: 'key_label_total_sents', type: 'number' },
                                                cost: { label: 'key_label_total_cost', type: 'number' },
                                                sms_success_count: { label: 'key_label_success_count', type: 'number' },
                                                sms_failed_count: { label: 'key_label_failed_count', type: 'number' },
                                                hr_line: { label: hrLine, type: 'line' }, //對 這只是一條線喔
                                                sms_failed_contacts_issue_count: { label: 'key_label_sms_failed_contacts_issue_count', type: 'number' },
                                                sms_failed_phone_num_issue_count: { label: 'key_label_sms_failed_phone_num_issue_count', type: 'number' },
                                                sms_failed_telecom_blacklist_count: { label: 'key_label_sms_failed_telecom_blacklist_count', type: 'number' },
                                                sms_failed_others_reason_count: { label: 'key_label_sms_failed_others_reason_count', type: 'number' },
                                            }
                                        },
                                        pieLabels: {
                                            title: 'label_title_campaign_success_and_failed',
                                            keys: {
                                                sms_success_rate: 'pie_key_campaign_success_rate',
                                                sms_failed_contacts_issue: 'pie_key_sms_failed_contacts_issue',
                                                sms_failed_phone_num_issue: 'pie_key_sms_failed_phone_num_issue',
                                                sms_failed_telecom_blacklist: 'pie_key_sms_failed_telecom_blacklist',
                                                sms_failed_others_reason: 'pie_key_sms_failed_others_reason',
                                            }
                                        },
                                        colors: ['#e85151', '#8C8C8C', '#A1A1A1', '#B5B5B5', '#C4C4C4'],
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row xs="auto" className="mb-2 mailSendingTabs px-5 pb-4">
                        <Col xs={12} className="h5 text-center text-orca pt-3">
                            {t('create_campaign_remarketing_tag', { ns: 'common'})}
                        </Col>
                        <Col xs={12} className="fs-6 text-center text-orcaLighter pt-2 pb-4">
                            {t('select_campaign_remarketing_tag_type', { ns: 'common'})}&ensp;
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip className="Tips-mailCampaign">
                                        {t('campaign_remarketing_tag_reminder', { ns: 'common'})}
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-orcaIcon2" />
                                </span>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={3}>
                            <p className="text-orcaMid fw-bolder mb-3">
                                <FontAwesomeIcon icon={faListCheck} />
                                &ensp;{t('campaign_remarketing_tag_short_url_click_title', { ns: 'common'})}
                            </p>
                            <div className="rounded px-1 d-flex flex-column justify-content-center" style={{ border: 'dashed', borderColor: '#ddd', height:'10rem' }}>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="trans-click-tag" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('campaign_remarketing_tag_click_members', { ns: 'common'})}
                                </Button><br />
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="trans-unclick-tag" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('campaign_remarketing_tag_unclick_members', { ns: 'common'})}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                    navigateToPayment: apiStatus.navigateToPayment,
                    balance: apiStatus.balance,
                    cost: apiStatus.cost,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
        </div>


    )
}