const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const WORKFLOW_WAIT_DAY_OPTION_COUNT = Number(process.env.REACT_APP_WORKFLOW_WAIT_DAY_OPTION_COUNT) || 10;

const BACKEND_URL = ENVIRONMENT === "local" ? "http://localhost:8000" : "";
const WEBSOCKET_DOMAIN = ENVIRONMENT === 'local' ? "ws://127.0.0.1:8000" : `wss://${DOMAIN}`;
const FACEBOOK_DOMAIN = ENVIRONMENT === 'local' ? `http://${DOMAIN}:8000` : `https://${DOMAIN}`;
const SHOW_SMS_IN_WORKFLOW = ENVIRONMENT === 'production' ? false : true;
const SHOW_HOUR_OPTION_FOR_DEV = ENVIRONMENT === 'production' ? false : true;

export { 
  ENVIRONMENT, 
  BACKEND_URL, 
  WEBSOCKET_DOMAIN, 
  FACEBOOK_DOMAIN,
  SHOW_SMS_IN_WORKFLOW,
  WORKFLOW_WAIT_DAY_OPTION_COUNT,
  SHOW_HOUR_OPTION_FOR_DEV
};
